
import { Options, mixins } from "vue-class-component";
import { AlbumAndAssetManagementMixin } from "@/mixins";
import { assetsModule } from "@/store";
import { AssetsInterface } from "@/types";
import ImageWithLoader from "@/components/ui/BaseImage/ImageWithLoader.vue";
import PinnedAssetModal from "./PinnedAssetModal/index.vue";
import AssetCard from "./AssetCard/index.vue";
import { VueDraggableNext } from "vue-draggable-next";

@Options({
  name: "HomeSliderPinnedAssets",
  components: {
    ImageWithLoader,
    PinnedAssetModal,
    AssetCard,
    draggable: VueDraggableNext,
  },
})
export default class HomeSliderPinnedAssets extends mixins(
  AlbumAndAssetManagementMixin
) {
  protected pinnedAssetData: AssetsInterface | null = null;

  protected get getPinnedAssets(): AssetsInterface[] {
    const albums = assetsModule.hasFilterActive
      ? assetsModule.getFilteredAlbums
      : assetsModule.getFoldersWithAssets;

    return assetsModule.getPinnedAssets.filter((asset: AssetsInterface) =>
      this.findAssetBySlug(albums, asset.slug)
    );
  }

  protected set getPinnedAssets(newPinnedAssets: AssetsInterface[]) {
    const iDs = newPinnedAssets.map((asset: AssetsInterface) => asset.id);
    assetsModule.setPinnedAssetsData(newPinnedAssets);
    assetsModule.SortPinnedAssets({ iDs });
  }
}
